.dragable-players {
  margin: auto;
  display: flex;
  padding: 0.3em;
  border-radius: 5px;
  background-color: rgb(27, 27, 27);
  border: 1px solid rgb(156, 156, 48);
  max-width: 7em;
  max-height: 4em;
  margin-bottom: 1em;
  cursor: grab;
  transition: transform 250ms;


  .player-logo {
    img {
      border-radius: 50%;
      max-width: 3em;
    }
  }

  .player-name {
    // width: 50%;
    margin: auto;
    padding: 0.5rem;
    color: rgb(223, 222, 222);
    span {
      font-size: 1.4rem;
    }
  }

  &:hover {
    transform: translateY(-20px);
    box-shadow: rgba(189, 115, 115, 0.56) 0px 15px 60px 15px;
  }

  @media (max-width: 1540px) {
    margin-top: 1em;
    // margin-left: 1em;
    min-width: 7em;
    max-height: 7vh;
    .player-logo {
      max-width: 2em;

      img {
        border-radius: 50%;
        max-width: 2em;
      }
    }
  }
  @media (max-width: 760px) {
    margin-top: 1em;
    .player-logo {
      max-width: 2em;
      img {
        border-radius: 50%;
        max-width: 2em;
      }
    }
    &:hover {
      transform: translateY(0);
      box-shadow: 0 0 0 0;
    }
  }

  @media (max-width: 700px){
    .player-name{
      display: none;
    }
  }
}
