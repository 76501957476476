.player-box {
  margin-top: 1em;
  // height: 50em;
  max-width: 7em;
  // border: 1px solid rgb(124, 124, 19);
  box-shadow: rgba(12, 46, 41, 0.56) 0px 15px 60px 15px;

  .player-contents {
    display: flex;
    flex-direction: column-reverse;
    max-width: 7em;
    min-height: 20em;
    // max-height: 27em;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid rgb(125, 126, 126);
    // background-color: rgb(3, 3, 3);
  }

  .player-contents-drop {
    display: flex;
    flex-direction: column-reverse;
    min-height: 20em;
    max-width: 7em;
    border: 3px solid rgb(98, 187, 62);
    background-color: rgb(97, 97, 97);
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  .default-message {
    margin: auto;
    text-align: center;
    color: rgb(128, 125, 125);
  }

  .player-info {
    display: flex;
    border-radius: 5px;
    padding: 5px;
    // margin-top: 0.5em;
    max-height: 4em;
    background-color: rgb(4, 51, 54);
    border-bottom: 3px solid rgb(148, 146, 146);
    border-top: 10px solid rgb(15, 31, 27);
    border-left: 3px solid rgb(148, 146, 146);
    border-right: 3px solid rgb(148, 146, 146);

    filter: drop-shadow(0.5rem 1.5rem 1rem rgba(129, 128, 128, 0.5));

    img {
      max-width: 3em;
      border-radius: 50%;
    }

    span {
      margin: auto;
      color: rgb(192, 191, 191);
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
  }

  .dropped-player {
    padding: 0.4em;
    margin-top: 0.2em;
    position: relative;
    display: flex;
    border-radius: 5px;
    max-width: 7em;
    background-color: rgb(48, 48, 48);
    border: 2px solid rgb(156, 156, 48);
    max-height: 4vh;
    transition: all 0.2s ease-in-out;

    span {
      color: white;
      margin: auto;
    }

    .delete-player {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 5em;
      color: white;
      // border: 1px solid red;
    }

    img {
      border-radius: 50%;
      max-width: 3em;
    }
  }


  @media (max-width: 1460px) {
    margin-top: 0em;
    // max-width: 10em;

    .player-info {
      justify-content: center;
      span {
        display: none;
      }
    }

    .dropped-player {
      display: flex;
      justify-content: center;
      max-height: 5em;
      span {
        display: none;
      }
      img {
        max-width: 2em;
      }
    }
  }

  @media (max-width: 910px) {
    margin-top: 0em;
    max-width: 7em;
    span {
      display: none;
    }

    .player-info {
      justify-content: center;
      img {
        max-width: 2em;
      }
    }

    .dropped-player {
      img {
        max-width: 1.5em;
      }
    }
  }

  @media (max-width: 700px) {
    .player-contents {
      min-height: 10em;
      
    }
  
    .player-contents-drop {
      min-height: 10em;
    }
  }
}

